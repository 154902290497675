import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import Link from '../components/link'
import { GatsbyImage } from '../components/image'

function HomepageLink({ title, image, link, colour }) {
  return (
    <Link to={link} className='homepage-link' style={{ color: colour }}>
      <GatsbyImage data={image?.large} />
      <br />
      <h1 className='a1 outerx2'>{title}</h1>
    </Link>
  )
}

export default function IndexPage({ data }) {
  const { links } = data?.homepage?.nodes?.[0] || {}
  const length = links?.length
  const layoutClassName = links.length > 3 ? 'layout-grid' : 'layout-flex'

  return (
    <Layout
      menuStyle={{
        backgroundColor: 'var(--white)',
        color: 'var(--black)'
      }}
      backgroundColor={'image'}
    >
      {length < 4 ? (
        <Main className={`homepage-layout layout-flex has-${length}`}>
          {links?.map((link, i) => (
            <HomepageLink {...link} key={`link${i}`} />
          ))}
        </Main>
      ) : (
        <Main className={`homepage-layout layout-flex has-${length}`}>
          <HomepageLink {...links[0]} key={`link0`} />
          <div className='homepage-link-group'>
            <HomepageLink {...links[1]} key={`link1`} />
            <HomepageLink {...links[2]} key={`link2`} />
          </div>
          <HomepageLink {...links[3]} key={`link3`} />
        </Main>
      )}
    </Layout>
  )
}

export { HomepageMeta as Head } from '../components/meta'

export const query = graphql`
  query {
    homepage: allContentfulHomepage(limit: 1) {
      nodes {
        links {
          title
          link
          colour
          image {
            id
            file {
              contentType
              url
            }
            description
            large: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        backgroundImage {
          ...ContentfulMedia
        }
        foregroundColour
        backgroundColour
        footerForegroundColour
      }
    }
  }
`
